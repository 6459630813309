export enum RequestStatus {
	still = 'STILL',
	loading = 'LOADING',
	failed = 'FAILED',
}

export type ResponseStatus = 'Finished' | 'InProgress' | 'Cancelled';

export enum UrlAPI {
	// Authentication
	login = 'login',
	logout = 'logout',
	dictionaries = 'dictionaries',

	// Main search page
	companies = 'companies',

	// Dashboard
	dashboard = 'dashboard',

	// admin upload companies
	company = 'company',
	adminCompany = 'company/admin',

	// admin upload people
	adminPeople = 'people/admin',

	// Database results
	metadata = 'metadata',
	people = 'people',

	// Upload to gold
	toGold = 'updateCompanyToGold',

	// people duplicates
	duplicatePeople = 'duplicatePeople',

	// Merge tool
	mergeToolDiff = 'mergetool/diff',
	mergeToolSave = 'mergetool/save',
	mergeToolMove = 'mergetool/move',
	mergeToolSavePeople = 'mergetool/savepeople',
	mergeToolCompanies = 'mergetool/companies',
	mergeToolTransferChildren = 'mergetool/transferChildren',
	mergeToolCancel = 'mergetool/cancel',

	// Documents
	documentsUpload = 'documents/upload',
	documentsUpdate = 'documents/update',
	documentsLookup = 'documents/lookup',
	documentsCounts = 'documents/counts',
	documentsCountsUsed = 'documents/counts/used',
	documentsYears = 'documents/years',
	documentsTypes = 'documents/types',
	documentsTypesInfo = 'documents/types/info',
}

export enum URLQcDashboard {
	qcDashboardWidgets = 'metadata',

	qcMissingCountries = 'qc/missingCountries',
	qcMissingGenderValues = 'qc/missingGenderValues',
}

export enum UrlKPI {
	kpiDatabase = 'kpiDatabase',
	kpiCompaniesDatapoints = 'kpiCompaniesDatapoints',
	kpiTotalByRegion = 'kpiTotalByRegion',
	kpiTotalByIndustry = 'kpiTotalByIndustry',
	kpiProductivity24 = 'kpiProductivity24',
	kpiProductivityHistorical = 'kpiProductivityHistorical',
	kpiTimeSpent = 'kpiTimeSpent',

	// In development...
	kpiCompaniesRating = 'kpiCompaniesRating',
	kpiPushedToGold = 'kpiPushedToGold',
	kpiAddedDatapoints = 'kpiAddedDatapoints',
	kpiNewCompanies = 'kpiNewCompanies',
	kpiLastEdited = 'kpiLastEdited',
}

export type CustomHost = 'qc';

export interface RequestUrlArgs {
	url: string;
	params?: string;
	customHost?: CustomHost;
}

interface FetchBase {
	url: string;
	params?: object;
	signal?: AbortSignal;
	customHost?: CustomHost;
}

export interface FetchGET extends FetchBase {
	token?: string;
}

export interface FetchPOST extends FetchBase {
	payload?: object;
}

export interface FetchPUT extends FetchBase {
	payload?: object;
}

export interface FetchDEL extends FetchBase {}
export class ResponseError extends Error {
	severity: ErrorSeverity;
	incidentId?: string;

	constructor(message: string, severity: ErrorSeverity, incidentId?: string, ...params: any) {
		super(...params);
		this.severity = severity;
		this.message = message;
		this.incidentId = incidentId;
	}
}
// * Types

export type SignInBody = {
	username: string;
	password: string;
};

export type ErrorSeverity = 'Information' | 'Warning' | 'Error' | 'AccessDenied' | 'Critical'; // * RTK api
